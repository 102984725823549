const state = {
    search: '', // Search query
    quickForm: {
        customer_type: '',
        client_type: '',
    },
    form: {
        customer_type: '',
        from: null,
        to: null,
        affiliate_id: null,
        phone: '',
    },
    isAdvance: false, // Advanced search mode
};

const mutations = {
    SET_SEARCH(state, search) {
        state.search = search;
    },
    SET_QUICK_FORM(state, quickForm) {
        state.quickForm = { ...quickForm };
    },
    SET_FORM(state, form) {
        state.form = { ...form };
    },
    SET_IS_ADVANCE(state, isAdvance) {
        state.isAdvance = isAdvance;
    },
    RESET_FILTERS(state) {
        state.search = '';
        state.quickForm = { customer_type: '', client_type: '' };
        state.form = {
            customer_type: '',
            from: null,
            to: null,
            affiliate_id: null,
            phone: '',
        };
        state.isAdvance = false;
    },
};

const actions = {
    updateSearch({ commit }, search) {
        commit('SET_SEARCH', search);
    },
    updateQuickForm({ commit }, quickForm) {
        commit('SET_QUICK_FORM', quickForm);
    },
    updateForm({ commit }, form) {
        commit('SET_FORM', form);
    },
    updateIsAdvance({ commit }, isAdvance) {
        commit('SET_IS_ADVANCE', isAdvance);
    },
    resetFilters({ commit }) {
        commit('RESET_FILTERS');
    },
};

const getters = {
    search: (state) => state.search,
    quickForm: (state) => state.quickForm,
    form: (state) => state.form,
    isAdvance: (state) => state.isAdvance,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};