<template>
  <layout title="Manage Affiliates">
    <add-button
      :to="{ name: 'add-affiliate' }"
      slot="add"
      v-if="hasEdit('affiliates')"
      >Add Affiliate</add-button
    >
    <static-table-view
      :show-search="true"
      :list="list"
      :headers="headers"
      search-placeholder="Search by Name or Email"
    >
      <template v-slot:item.name="{ value, item }">
        <router-link
          class="text-decoration-none"
          :to="{ name: 'affiliate-transaction', params: { id: item.id } }"
        >
          {{ value }}
        </router-link>
      </template>
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}
      </template>
      <template v-slot:item.total="{ value, item }">
        <affiliate-commissions-list
          :affiliate="item"
          :value="formatPrice(value || 0)"
          type="earned"
        />
      </template>
      <template v-slot:item.commission="{ value, item }">
        <affiliate-commissions-list
          :affiliate="item"
          :value="formatPrice(value || 0)"
          type="paid"
        />
      </template>
      <template v-slot:item.commission_owed="{ value, item }">
        <affiliate-commissions-list
          :affiliate="item"
          :value="formatPrice(value || 0)"
          type="owed"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <delete-icon-button
            v-if="hasDelete('affiliates')"
            @click="removeAffiliate(item)"
          />
          <edit-icon-button
            v-if="hasEdit('affiliates')"
            :to="{ name: 'update-affiliate', params: { id: item.id } }"
          ></edit-icon-button>
          <!-- <v-btn
            icon
            color="orange"
            
          >
            <v-icon>mdi-history</v-icon>
          </v-btn> -->
          <affiliate-payout
            v-if="item.commission_owed > 0 && canPayout"
            :item="item"
          />
        </div>
      </template>
    </static-table-view>
  </layout>
</template>
<script>
import Layout from "@/components/ui/Layout.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getHeaders } from "@/assets/utils/common.utils";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import { deleteDoc, doc } from "firebase/firestore";
import { colAffiliates } from "@/assets/utils/firebase.utils";
import AffiliatePayout from "@/components/affiliates/AffiliatePayout.vue";
import AffiliateCommissionsList from "@/components/affiliates/AffiliateCommissionsList.vue";
import EditIconButton from "@/components/ui/buttons/EditIconButton.vue";
export default {
  components: {
    Layout,
    AddButton,
    StaticTableView,
    DeleteIconButton,
    AffiliatePayout,
    AffiliateCommissionsList,
    EditIconButton,
  },
  computed: {
    headers() {
      let doc = {
        name: "Name",
        email: "Email",
        total: "Total Commission Earned",
        commission: "Commission Paid",
        commission_owed: "Commission Owed",
        updated_date: "Updated On",
      };
      if (this.hasEdit("affiliates") || this.hasEdit("affiliates")) {
        doc.actions = "Actions";
      }
      return getHeaders(doc);
    },
    list() {
      return [...this.affiliates].sort((a, b) => {
        return b.commission_owed - a.commission_owed;
      });
    },
  },
  methods: {
    removeAffiliate({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this Affiliate?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colAffiliates, id));
              vm.notifySuccess("Affiliate has been deleted successfully.");
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
  },
  mounted() {},
};
</script>

<style></style>
