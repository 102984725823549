<template>
  <v-dialog v-model="dialog" max-width="650px" persistent>
    <template v-slot:activator="{ on }">
      <a v-on="selected.length == 0 ? null : on">
        <primary-button class="ml-3" :disabled="selected.length == 0"
          >Change %</primary-button
        >
      </a>
    </template>
    <dialog-card
      title="Change commissions % of all the selected transactions"
      @submit="applyCommission()"
      :loading="loading"
      @close="dialog = false"
    >
      <input-field
        v-model="commission_rate"
        rules="required|commission_rate"
        label="New Commission %"
      />
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <close-button @click="dialog = false" :disabled="isLoading"
          >Cancel</close-button
        >
        <secondary-button type="submit" :loading="isLoading"
          >Apply</secondary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import transactionMixin from "@/mixins/transaction.mixin";
import CloseButton from "../ui/buttons/CloseButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    affiliate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      dialog: false,
      commission_rate: "",
    };
  },
  components: {
    PrimaryButton,
    DialogCard,
    CloseButton,
    SecondaryButton,
    InputField,
  },
  mixins: [transactionMixin],
  watch: {
    dialog(val) {
      if (val) {
        this.commission_rate = (this.affiliate.commission_rate || 0) * 100;
      }
    },
  },
  methods: {
    afterApplyCommission() {
      this.$emit("onUpdate");
      this.dialog = false;
    },
  },
};
</script>
