<template>
  <layout title="Contract History">
    <static-table-view
      :isTop="false"
      :loading="loading"
      :list="items"
      :headers="headers"
    >
      <template v-slot:item.status="{ value }">
        <v-chip :color="getStatusColor(value)" class="text-capitalize">
          {{ value }}</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center">
          <add-contract @update="fetchContracts()" :item="item" />
          <client-agreement-preview-dialog :isIcon="true" :agreement="item" />
          <delete-agreement :item="item" @onDelete="fetchContracts()" />
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import ClientAgreementPreviewDialog from "@/components/clients/ClientAgreementPreviewDialog.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { colClientAgreements } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import DeleteAgreement from "@/components/mycompany/DeleteAgreement.vue";
import AddContract from "@/components/clients/clientProfile/AddContract.vue";
export default {
  components: {
    Layout,
    ClientAgreementPreviewDialog,
    StaticTableView,
    DeleteAgreement,
    AddContract,
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: "Name",
          value: "customer_name",
        },
        {
          text: "Status",
          value: "status",
          width: "150px",
        },
        {
          text: "Created At",
          value: "created_at",
          width: "200px",
        },
        {
          text: "Actions",
          value: "action",
          width: "100px",
        },
      ],
      loading: false,
    };
  },
  methods: {
    getStatusColor(value) {
      switch (value) {
        case "signed":
          return "success";
        case "sent":
          return "warning";
        default:
          return "lightgrey";
      }
    },
    async fetchContracts() {
      try {
        this.items = [];
        this.loading = true;
        let listQuery = query(
          colClientAgreements,
          orderBy("created_at", "desc"),
          where("customer_id", "==", this.$route.params.id)
        );
        this.items = mapDocs((await getDocs(listQuery)).docs);
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchContracts();
  },
};
</script>

<style></style>
