<template>
  <v-dialog v-model="dialog" max-width="500px" v-if="canPayout" persistent>
    <template v-slot:activator="{ on }">
      <span v-on="disabled ? null : on">
        <secondary-button :disabled="disabled"> Mark as Paid </secondary-button>
      </span>
    </template>
    <dialog-card
      title="Mark the selected commissions as paid"
      @close="dialog = false"
      @submit="submit()"
      :loading="loading"
    >
      <p class="black--text body-medium mb-5">
        All the selected Commissions will be marked as paid to the respective
        Affiliate(s). Total {{ formatPrice(owed) }}
      </p>
      <select-field
        label="Payment Method"
        :items="paymentMethods"
        v-model="payment_method"
      ></select-field>
      <date-field v-model="paid_at" rules="required" />
      <b class="black--text body-medium">Are you Sure you want to proceed?</b>
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <close-button @click="dialog = false" :disabled="loading"
          >No</close-button
        >
        <secondary-button type="submit" :loading="loading" :disabled="loading"
          >Yes</secondary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { functions } from "@/assets/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import CloseButton from "../ui/buttons/CloseButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import SelectField from "../ui/form/SelectField.vue";
import DateField from "../ui/form/DateField.vue";
export default {
  components: {
    CloseButton,
    SecondaryButton,
    DialogCard,
    SelectField,
    DateField,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      payment_method: "",
      paid_at: "",
    };
  },
  computed: {
    owed() {
      return this.selected
        .filter((i) => i.payout == false)
        .reduce((a, i) => parseFloat(i.commission) + a, 0);
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let payoutAction = httpsCallable(functions, "payoutAction");
        let ids = vm.selected.filter((i) => i.payout == false).map((i) => i.id);
        let data = {
          payment_method: vm.payment_method,
          ids,
          type: "selected-payout",
          paid_at: vm.paid_at,
        };
        await payoutAction(data);
        vm.$emit("onPayout");
        vm.payment_method = "";
        vm.paid_at = "";
        vm.dialog = false;
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
