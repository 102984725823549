<template>
  <v-dialog v-model="dialog" persistent max-width="1440px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-if="isIcon" icon v-on="on"
        ><v-icon>mdi-eye</v-icon></v-btn
      >
      <a v-else v-on="on"> View Client Agreement </a>
    </template>
    <dialog-card
      :title="`Agreement Preview`"
      :isSubmit="false"
      @close="dialog = false"
    >
      <div
        outlined
        class="grey lighten-4 pa-5 pr-10"
        :style="{ height: minHeight, overflowY: 'auto' }"
      >
        <div v-html="agreement.agreement"></div>
        <template v-if="agreement.digital_signature">
          <strong>My Digital Signature</strong>
          <span style="display: block">
            <img :src="agreement.digital_signature" width="300" height="80" />
          </span>
          <p style="color: #4a4a4a; font-size: 14px; font-weight: bold">
            {{ agreement.customer_name }}, {{ agreement.signedAt }}
          </p>
          <v-card outlined color="#fffcd9" class="pa-5">
            <b>Digital Signature:</b> <br />

            <br />

            Client Name: {{ agreement.customer_name }} <br />
            <template v-if="agreement.client">
              Email: {{ agreement.client.email }}<br />

              Phone:{{ agreement.client.mobile }} <br />

              Address: {{ formatAddress(agreement.client) }} <br />
            </template>

            Verified receipt and agreed:
            {{ agreement.verified_and_agreed_on == true ? "yes" : "no" }} <br />

            Timestamp: {{ agreement.timestamp }}<br />

            IP Address: {{ agreement.ip_address }}
            <br />
            <br />
            *<b>Digital Signatures</b>: In 2000, the U.S. Electronic Signatures
            in Global and National Commerce (ESIGN) Act established electronic
            records and signatures as legally binding, having the same legal
            effects as traditional paper documents and handwritten signatures.
            Read more at the FTC web site:
            <a
              class="primary--text"
              href="http://www.ftc.gov/os/2001/06/esign7.htm"
              target="_blank"
              >http://www.ftc.gov/os/2001/06/esign7.htm</a
            >
          </v-card>
        </template>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "../ui/DialogCard.vue";
export default {
  props: {
    isIcon: Boolean,
    agreement: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    minHeight() {
      return `${window.innerHeight - 300}px`;
    },
  },
  components: { DialogCard },
  methods: {
    formatAddress(client) {
      let address = [];
      let { street, state, postal_code, city } = client;
      if (street) address.push(street);
      if (city) address.push(city);
      if (state) address.push(state);
      if (postal_code) address.push(postal_code);
      return address.toString().split(",").join(", ");
    },
  },
};
</script>

<style></style>
