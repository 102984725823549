<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <template v-slot:activator="{ on }">
      <a v-on="selected.length == 0 ? null : on">
        <primary-button class="ml-3" :disabled="selected.length == 0"
          >Change Payment Method</primary-button
        >
      </a>
    </template>
    <dialog-card
      title="Change Payment Method"
      @submit="submit()"
      @close="dialog = false"
      :loading="loading"
    >
      <select-field
        :items="paymentMethods"
        rules="required"
        v-model="status"
        label="Payment Method"
      />
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <close-button @click="deactivate()" :disabled="isLoading"
          >Cancel</close-button
        >
        <secondary-button type="submit" :loading="isLoading"
          >Submit</secondary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import CloseButton from "../ui/buttons/CloseButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import SelectField from "../ui/form/SelectField.vue";
import { doc, writeBatch } from "firebase/firestore";
import { colCommissions, db } from "@/assets/utils/firebase.utils";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      loading: false,
      status: "",
    };
  },
  components: {
    PrimaryButton,
    DialogCard,
    CloseButton,
    SecondaryButton,
    SelectField,
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.isLoading = true;
        const batch = writeBatch(db);
        vm.selected.forEach((i) => {
          let updateDoc = {
            updated_at: getDate(),
            payout_payment_method: vm.status,
            updated_by: vm.userEmail,
          };
          batch.update(doc(colCommissions, i.id), updateDoc);
        });
        await batch.commit();
        vm.$emit("onUpdate");
        vm.isLoading = false;
        vm.status = "";
        vm.dialog = false;
        vm.notifySuccess("Payment method has been changed successfully.");
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
  },
};
</script>
