export default {
  "auth/app-deleted":
    "The application has been deleted. Please contact support.",
  "auth/app-not-authorized":
    "The application is not authorized to use Firebase Authentication.",
  "auth/argument-error":
    "An invalid argument was provided. Please check your input.",
  "auth/invalid-api-key":
    "The API key provided is invalid. Please check your configuration.",
  "auth/invalid-user-token": "Your session has expired. Please sign in again.",
  "auth/network-request-failed":
    "A network error occurred. Please try again later.",
  "auth/requires-recent-login": "Please sign in again to continue.",
  "auth/too-many-requests":
    "You have made too many requests. Please try again later.",
  "auth/unauthorized-domain":
    "This domain is not authorized to perform this operation.",
  "auth/user-disabled":
    "This account has been disabled. Please contact support.",
  "auth/user-token-expired": "Your session has expired. Please sign in again.",
  "auth/web-storage-unsupported":
    "Your browser does not support web storage. Please upgrade your browser.",
  "auth/email-already-in-use":
    "The email address is already in use by another account.",
  "auth/invalid-email": "The email address is invalid.",
  "auth/invalid-password": "The password is invalid. Please try again.",
  "auth/wrong-password": "The password is incorrect. Please try again.",
  "auth/user-not-found": "No user found with this email address.",
  "auth/weak-password":
    "The password is too weak. Please choose a stronger password.",
  "auth/operation-not-allowed":
    "This operation is not allowed. Please contact support.",
  "auth/account-exists-with-different-credential":
    "An account already exists with the same email address but different sign-in credentials.",
  "auth/credential-already-in-use":
    "This credential is already associated with a different user account.",
  "auth/email-change-needs-verification":
    "Please verify your email address before proceeding.",
  "auth/provider-already-linked":
    "This account is already linked with another provider.",
  "auth/no-such-provider": "No provider found for this account.",
  "auth/invalid-action-code":
    "The action code is invalid. It might be expired or already used.",
  "auth/expired-action-code": "The action code has expired.",
  "auth/captcha-check-failed": "The CAPTCHA check failed. Please try again.",
  "auth/missing-email":
    "The email address is missing. Please provide an email address.",
  "auth/invalid-verification-code":
    "The verification code is invalid. Please check and try again.",
  "auth/missing-verification-code":
    "The verification code is missing. Please provide the code.",
  "auth/invalid-verification-id": "The verification ID is invalid.",
  "auth/missing-verification-id": "The verification ID is missing.",
  "auth/invalid-phone-number":
    "The phone number is invalid. Please enter a valid phone number.",
  "auth/missing-phone-number":
    "The phone number is missing. Please provide a phone number.",
  "auth/invalid-credential": "The credential is invalid or has expired.",
  "auth/missing-credential":
    "The credential is missing. Please provide the required credentials.",
  "auth/invalid-message-payload": "The message payload is invalid.",
  "auth/invalid-recipient-email":
    "The recipient email is invalid. Please check and try again.",
  "auth/invalid-sender": "The sender email is invalid.",
  "auth/missing-iOS-bundle-id": "An iOS Bundle ID is missing.",
  "auth/missing-android-pkg-name": "An Android Package Name is missing.",
  "auth/auth-domain-config-required":
    "The authentication domain is not configured.",
  "auth/invalid-tenant-id": "The tenant ID is invalid.",
  "auth/tenant-id-mismatch":
    "The tenant ID in the request does not match the user's tenant ID.",
};
