import { isAuth } from "@/assets/utils/auth.utils";
import Clients from "@/pages/clients/Clients.vue";
import AddClient from "@/pages/clients/AddClient.vue";
import ClientDashboard from "@/pages/clients/ClientDashboard.vue";
import ClientProfile from "@/pages/clients/ClientProfile.vue";
import ImportAudit from "@/pages/clients/ImportAudit.vue";
import CreditReport from "@/pages/clients/CreditReport.vue";
import GenerateLetters from "@/pages/clients/GenerateLetters.vue";
import SendLetters from "@/pages/clients/SendLetters.vue";
import DisputeInfo from "@/pages/clients/DisputeInfo.vue";
import ReferralPayout from "@/pages/clients/ReferralPayout.vue";
import ContractHistory from "@/pages/clients/ContractHistory.vue";
import ClientTransactionHistory from "@/pages/clients/ClientTransactionHistory.vue";
import ClientSavedReports from "@/pages/clients/ClientSavedReports.vue";
import ClientSavedLetters from "@/pages/clients/ClientSavedLetters.vue";
import DisputeLetters from "@/pages/disputeLetters/DisputeLetters.vue";
import Agreement from "@/pages/clients/Agreement.vue";

export default [
  {
    name: "clients",
    path: "/",
    component: Clients,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "customers",
    },
  },
  {
    name: "add-client",
    path: "/add-client",
    component: AddClient,
    beforeEnter: isAuth,
    meta: {
      permission: "edit",
      way: "customers",
    },
  },
  {
    name: "agreement",
    path: "/agreement/:id",
    component: Agreement,
  },

  {
    name: "client-dashboard",
    path: "/client-dashboard",
    component: ClientDashboard,
    beforeEnter: isAuth,
    children: [
      {
        path: "/client-dashboard/:id",
        name: "client-profile",
        component: ClientProfile,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/client-saved-reports/:id",
        name: "client-saved-reports",
        component: ClientSavedReports,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/client-saved-letters/:id",
        name: "client-saved-letters",
        component: ClientSavedLetters,
        beforeEnter: isAuth,
        meta: {
          permission: "saved-letters",
          way: "customers",
        },
      },
      {
        path: "/update-client/:id",
        name: "update-client",
        component: AddClient,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "customers",
        },
      },
      {
        path: "/import-audit/:id",
        name: "import-audit",
        component: ImportAudit,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/credit-report/:id",
        name: "credit-report",
        component: CreditReport,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/generate-letters/:id",
        name: "generate-letters",
        component: GenerateLetters,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/send-letters/:id",
        name: "send-letters",
        component: SendLetters,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "send-faxes",
        },
      },

      {
        path: "/dispute-info/:id",
        name: "dispute-info",
        component: DisputeInfo,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/dispute-letters/:id",
        name: "client-dispute-letters",
        component: DisputeLetters,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/referral-payout/:id",
        name: "referral-payout",
        component: ReferralPayout,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/contract-history/:id",
        name: "contract-history",
        component: ContractHistory,
        meta: {
          permission: "view",
          way: "customers",
        },
      },
      {
        path: "/client-transaction-history/:id",
        name: "client-transaction-history",
        component: ClientTransactionHistory,
        meta: {
          permission: "view",
          way: "transaction-log",
        },
      },
    ],
  },
];
