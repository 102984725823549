<template>
  <div>
    <label class="body-small" v-if="label">{{ label }}</label>
    <v-combobox
      v-model="model"
      :items="items"
      :class="label ? 'mt-1' : ''"
      :loading="isLoading"
      :search-input.sync="search"
      outlined
      :label="inlineLabel"
      clearable
      :dense="dense"
      :hide-details="hideDetails"
      hide-no-data
      :disabled="disabled"
      :rules="getRules"
      @change="$emit('input', model)"
    />
  </div>
</template>

<script>
import { getDocs, limit, query, where } from "firebase/firestore";
import { colClients } from "@/assets/utils/firebase.utils";
import { transformCustomer } from "@/assets/utils/doc.utils";
import { getRules, inputDense } from "@/assets/utils/common.utils";
export default {
  props: {
    disabled: Boolean,
    hideDetails: {
      type: Boolean,
      default: true,
    },
    inlineLabel: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: "Search by name",
    },
    rules: String,
    dense: {
      type: Boolean,
      default: inputDense,
    },
  },
  data() {
    return {
      isLoading: false,
      model: null,
      search: null,
      items: [],
    };
  },
  watch: {
    value() {
      this.model = this.value;
      this.search = "";
    },
    search() {
      this.id = "";
      this.initSearch();
    },
  },
  computed: {
    getRules() {
      return getRules(this.rules || "");
    },
  },
  methods: {
    getName({ fields, name, job_number }) {
      return `${name} - ${fields.Phone} - #${job_number}`;
    },
    async initSearch() {
      const vm = this;
      if (this.model) {
        let item = this.items.find((i) => i.id == this.model);
        if (item != null) return;
      }
      if (vm.search == null) return;
      if (vm.search.length < 3) return;
      if (vm.isLoading) return;
      vm.isLoading = true;
      vm.items = [];
      let terms = vm.search.toLowerCase();
      try {
        let q = query(
          colClients,
          where("terms", "array-contains", terms),
          limit(5)
        );
        let docs = (await getDocs(q)).docs;
        vm.items = docs.map((i) => {
          let item = transformCustomer(i.data());
          return item.name;
        });
        vm.isLoading = false;
        if (vm.search.length > 3 && terms.length < vm.search.length) {
          vm.initSearch();
        }
      } catch (error) {
        console.log(error.message);
        vm.isLoading = false;
      }
    },
    async init() {
      const vm = this;
      if (vm.value) {
        vm.model = this.value;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
