<template>
  <layout title="Clients">
    <editable-date-field v-if="false" />
    <client-filter
      :headers="headers"
      :items="list"
      ref="filter"
      @onUpdate="fetchClients(options)"
      @onFilterApply="onFilterApply"
    />
    <dynamic-table-view
      ref="table"
      @onPageChange="fetchClients"
      :show-select="hasEdit('customers')"
      :server-items-length="total"
      :headers="headers"
      v-model="selected"
      :loading="loading"
      :list="list"
    >
      <div slot="left" class="d-flex mt-2">
        <delete-selected-client
          @onUpdate="fetchClients(options)"
          :selected="selected"
          v-if="hasDelete('customers')"
        />
        <update-selected-client-status
          :selected="selected"
          v-if="hasEdit('customers')"
          @onUpdate="fetchClients(options)"
        />
      </div>
      <template v-slot:item.name="{ item }">
        <router-link
          class="text-decoration-none"
          :to="{ name: 'client-profile', params: { id: item.id } }"
        >
          {{ item.first_name }} {{ item.last_name }}
        </router-link>
      </template>
      <template v-slot:item.customer_type="{ item }">
        <update-client-status
          :statusUpdateId="statusUpdateId"
          @onUpdateClick="statusUpdateId = $event"
          @onUpdate="fetchClients(options)"
          :item="item"
        />
      </template>
      <template v-slot:item.contract_status="{ value }">
        <tooltip
          :message="getContractMessage(value)"
          color="white"
          bottom
          content-class="black--text"
        >
          <v-icon :color="value ? 'green' : 'red'">
            {{ value ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
        </tooltip>
      </template>
      <template v-slot:item.affiliate_id="{ value, item }">
        <div class="d-flex">
          {{ getAffiliateName(value) }}
          <client-other-affiliates v-if="value" :item="item" />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <client-action-menu :item="item" @onUpdate="fetchClients(options)" />
      </template>
    </dynamic-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import ClientFilter from "@/components/clients/ClientFilter.vue";
import DynamicTableView from "@/components/ui/DynamicTableView.vue";
import {
  fetchClientByIds,
  getHeaders,
  getStartEndDate,
} from "@/assets/utils/common.utils";
import UpdateClientStatus from "@/components/clients/UpdateClientStatus.vue";
import DeleteSelectedClient from "@/components/clients/DeleteSelectedClient.vue";
import UpdateSelectedClientStatus from "@/components/clients/UpdateSelectedClientStatus.vue";
import ClientOtherAffiliates from "@/components/clients/ClientOtherAffiliates.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import ClientActionMenu from "@/components/clients/ClientActionMenu.vue";
import EditableDateField from "@/components/ui/form/EditableDateField.vue";
import { algoliaClient } from "@/assets/utils/algolia.utils";
import moment from "moment-timezone";
import {
  getCountFromServer,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { colClients, isStaging } from "@/assets/utils/firebase.utils";
import { mapDocs, transformCustomer } from "@/assets/utils/doc.utils";
export default {
  components: {
    Layout,
    ClientFilter,
    DynamicTableView,
    UpdateClientStatus,
    DeleteSelectedClient,
    UpdateSelectedClientStatus,
    ClientOtherAffiliates,
    Tooltip,
    ClientActionMenu,
    EditableDateField,
  },
  data() {
    return {
      selected: [],
      options: {},
      form: {},
      total: 20,
      list: [],
      loading: false,
      statusUpdateId: "",
    };
  },
  computed: {
    headers() {
      let doc = {
        name: "Name",
        email: "Email",
        affiliate_id: "Affiliate",
        client_type: "Client Type",
        created_at: "Date Added",
        customer_type: "Status",
        contract_status: "Contract Status",
        actions: "Actions",
      };
      let sortable = [
        "name",
        "email",
        "affiliate_id",
        // "client_type",
        "created_at",
        "customer_type",
      ];
      if (!this.hasEdit("customers") && !this.hasDelete("customers")) {
        delete doc.actions;
      }

      return getHeaders(doc).map((i) => {
        i.sortable = sortable.indexOf(i.value) != -1;
        return i;
      });
    },
    indexName() {
      let indexName = "clients";
      let { sortBy, sortDesc } = this.options;
      if (sortBy) {
        let indexes = {
          name: "clients_name",
          email: "clients_email",
          affiliate_id: "clients_affiliate",
          client_type: "clients_client_type",
          created_at: "clients",
          customer_type: "clients_customer_type",
        };
        let index = Object.keys(indexes).find(
          (key) => sortBy.indexOf(key) != -1
        );
        if (index != null) {
          let suffix = sortDesc[0] == true ? "desc" : "asc";
          if (index == "created_at") {
            if (suffix == "asc") {
              indexName = `${indexes[index]}_${suffix}`;
            }
          } else {
            indexName = `${indexes[index]}_${suffix}`;
          }
        }
      }
      return indexName;
    },
    filters() {
      let { client_type, customer_type, affiliate_id, phone } = this.form;
      let and = [];
      let filters = [...this.$refs.filter.filterredTypes.map((i) => i.value)];
      let facetFilters = "";
      let { start, end } = getStartEndDate(this.form);
      if (start && end) {
        start = moment(start).unix();
        end = moment(end).unix();
        and.push({ created_at: { between: [start, end] } });
      }
      if (client_type) {
        and.push({
          client_type: {
            eq: client_type,
          },
        });
      } else if (filters.length < 3) {
        and.push({
          client_type: {
            in: filters,
          },
        });
      }
      if (customer_type) {
        and.push({
          customer_type: {
            eq: customer_type,
          },
        });
      }
      if (affiliate_id) {
        facetFilters = `affilaite_ids:${affiliate_id}`;
      }
      if (phone && phone.toString().length == 14) {
        let mobile = phone.toString().match(/\d/g).join("");
        and.push({
          or: [
            {
              mobile: { eq: mobile },
            },
            {
              home_phone: { eq: mobile },
            },
            {
              work_phone: { eq: mobile },
            },
          ],
        });
      }
      let AlgoliaQueryBuilder = require("algolia-search-builder");
      const algoliaQueryBuilder = new AlgoliaQueryBuilder(and);
      const filterQuery = algoliaQueryBuilder.exec();
      return { facetFilters, filterQuery };
    },
  },
  methods: {
    getContractMessage(value) {
      if (!value) return "Contract is not signed";
      return `Contract Signed On: ${this.formatDate(new Date())}`;
    },
    onFilterApply(event) {
      this.form = event;
      if (this.$refs.table) {
        let options = this.$refs.table.options;
        this.$refs.table.options = { ...options, page: 1 };
      }
    },

    query(options) {
      let { client_type, customer_type, affiliate_id, search, phone } =
        this.form;
      let queryList = [];
      let sortBy = options.sortBy[0];
      if (sortBy == "affiliate_id") sortBy = "affiliate_name";
      let sortDesc = options.sortDesc[0];
      let { start, end } = getStartEndDate(this.form);
      if (start && end) {
        queryList.push(orderBy("created_at", sortDesc ? "desc" : "asc"));
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      let filters = [
        ...this.$refs.filter.filterredTypes.map((i) => i.value),
        "",
      ];
      if (client_type) {
        queryList.push(where("client_type", "==", client_type));
      } else if (filters.length < 4) {
        queryList.push(where("client_type", "in", filters));
      }
      if (customer_type)
        queryList.push(where("customer_type", "==", customer_type));
      if (affiliate_id)
        queryList.push(where("affiliate_id", "==", affiliate_id));
      let terms = [];
      if (search) terms.push(search.trim().toLowerCase());
      if (phone) terms.push(phone.trim());
      if (terms.length > 0) {
        queryList.push(where("terms", "array-contains-any", terms));
      }
      if (sortBy) {
        if (sortBy == "created_at") {
          if (!start) {
            queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
          }
        } else {
          queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
        }
      } else if (!start) {
        queryList.push(orderBy("created_at", "desc"));
      }
      return {
        countQuery: query(colClients, ...queryList),
        pageQuery: query(
          colClients,
          ...[...queryList, ...this.getPaginationQuery(options)]
        ),
      };
    },
    async fetchStagingClients(options) {
      const vm = this;
      try {
        if (vm.loading) return;
        vm.loading = true;
        vm.options = options;
        vm.selected = [];
        vm.list = [];
        this.$refs.table.selected = [];
        let page = options.page;
        let { countQuery, pageQuery } = vm.query(options);
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        vm.list = mapDocs(docs)
          .map((i) => transformCustomer(i))
          .map((i) => {
            let x = Math.floor(Math.random() * 10 + 1);
            i.client_type = this.getClientType(i);

            return { ...i, contract_status: x % 2 == 0 };
          });
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
    async fetchClients(options) {
      if (isStaging) {
        this.fetchStagingClients(options);
        return;
      }
      try {
        this.loading = true;
        this.options = options;
        let searchText = this.form.search?.trim().toLowerCase() || "*";
        const index = algoliaClient.initIndex(this.indexName);
        let queryOptions = {
          page: this.options.page - 1,
          hitsPerPage: this.options.itemsPerPage,
          cacheable: true,
          exactOnSingleWordQuery: "attribute",
          typoTolerance: false,
        };
        let { facetFilters, filterQuery } = this.filters;
        if (filterQuery) queryOptions.filters = filterQuery;
        if (facetFilters) queryOptions.facetFilters = facetFilters;
        let res = await index.search(searchText, queryOptions);
        this.total = res.nbHits;
        let ids = res.hits.map((i) => i.objectID);
        this.list = await fetchClientByIds(ids);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleError(error);
      }
    },
  },
  mounted() {},
};
</script>
<style></style>
