<template>
  <div class="py-3">
    <div class="d-flex align-center">
      <v-tabs v-model="tab">
        <v-tab class="text-none">Current Tasks</v-tab>
        <v-tab class="text-none">Completed Tasks</v-tab>
      </v-tabs>
      <bulk-edit-tasks v-if="hasEdit('schedule')" />
      <template v-if="tab == 0">
        <select-field
          :items="users"
          v-model="member"
          :dense="false"
          style="flex: 0 0 220px"
          hide-details
          class="mr-3"
          inline-label="Team Members"
        />
        <select-field
          style="flex: 0 0 170px"
          v-model="due"
          v-if="false"
          :items="dueDates"
          :dense="false"
          hide-details
          class="mr-3"
          inline-label="Due Dates"
        />
      </template>
      <select-field
        v-else
        :dense="false"
        v-model="scheduleType"
        :items="scheduleTypes"
        hide-details
        style="flex: 0 0 220px"
        class="mr-3"
        inline-label="Tasks & Events"
      />
      <add-schedule-event
        v-if="hasEdit('schedule')"
        type="task"
        @onEventUpsert="resetTable()"
      />
    </div>
    <div v-if="tab == 0">
      <v-btn color="error" text @click="scrollToView('tableDue')"
        >Overdue</v-btn
      >
      <v-btn
        text
        color="success"
        @click="scrollToView('tableToday')"
        class="mx-2"
        >Today</v-btn
      >
      <v-btn text color="warning" @click="scrollToView('tableUpcoming')"
        >Upcoming</v-btn
      >
      <div id="tableDue" class="task-list">
        <task-event-table :member="member" ref="tableDue" type="Overdue" />
      </div>
      <div id="tableToday" class="task-list">
        <task-event-table :member="member" ref="tableToday" type="Today" />
      </div>
      <div id="tableUpcoming" class="task-list">
        <task-event-table
          :member="member"
          ref="tableUpcoming"
          type="Upcoming"
        />
      </div>
    </div>
    <div v-else>
      <task-event-table :scheduleType="scheduleType" :completed-tasks="true" />
    </div>
  </div>
</template>

<script>
import { auth } from "@/assets/utils/firebase.utils";
import SelectField from "../ui/form/SelectField.vue";
import AddScheduleEvent from "./AddScheduleEvent.vue";
import BulkEditTasks from "./BulkEditTasks.vue";
import TaskEventTable from "./TaskEventTable.vue";
export default {
  components: { SelectField, AddScheduleEvent, TaskEventTable, BulkEditTasks },
  data() {
    return {
      tab: 0,
      member: auth.currentUser?.uid || "",
      scheduleType: "",
      due: 0,
      taskTab: 0,
    };
  },
  inject: ["users"],
  computed: {
    scheduleTypes() {
      return [
        {
          name: "Tasks & Events",
          value: "",
        },
        {
          name: "Tasks",
          value: "task",
        },
        {
          name: "Events",
          value: "event",
        },
      ];
    },
    dueDates() {
      return ["All Due Dates", "Overdue", "Today", "Upcoming"].map(
        (name, value) => ({ name, value })
      );
    },
  },
  methods: {
    resetTable() {
      if (this.tab == 0) {
        this.$refs.tableDue.reset();
        this.$refs.tableToday.reset();
        this.$refs.tableUpcoming.reset();
      }
    },
    scrollToView(tab) {
      let el = document.getElementById(tab);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style>
.task-list {
  scroll-margin-top: 150px;
}
</style>
