<template>
  <v-btn
    :height="height"
    outlined
    color="primary"
    @click="$emit('click')"
    v-bind="$attrs"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    height: {
      type: String,
      default: "40px",
    },
  },
};
</script>

<style></style>
