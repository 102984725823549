<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="4">
        <div class="mb-3">
          Total Transaction Amount = <b>{{ formatPrice(total) }}</b>
        </div>
        <div>
          Total Commission Earned = <b>{{ formatPrice(earned) }}</b>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="mb-3">
          Total Commission Paid = <b>{{ formatPrice(paid) }}</b>
        </div>
        <div>
          Total Commission Owed = <b>{{ formatPrice(owed) }}</b>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="2">
        <date-field v-model="form.from" label="From Date" />
      </v-col>
      <v-col cols="2">
        <date-field v-model="form.to" label="To Date" />
      </v-col>
      <v-col cols="2">
        <select-field
          label="Payout Status"
          clearable
          v-model="form.payout"
          placeholder="All"
          :items="payoutStatus"
        ></select-field>
      </v-col>
      <v-col cols="3">
        <client-autocomplete
          v-model="form.client"
          label="Client"
          placeholder="All Clients"
        />
      </v-col>
      <v-col cols="3" class="pt-10 d-flex">
        <v-spacer></v-spacer>
        <close-button width="100px" @click="resetFilter()">Reset</close-button>
        <primary-button width="100px" @click="fetchTransactions()"
          >Apply</primary-button
        >
      </v-col>
    </v-row>
    <static-table-view
      ref="table"
      v-model="selected"
      :list="list"
      show-select
      :loading="loading"
      :headers="headers"
    >
      <div class="d-flex flex-1" slot="left">
        <affiliate-weekly-payout
          type="button"
          @onPayout="fetchTransactions()"
          v-if="hasEdit('affiliates')"
          :selected="unpaidSelected"
          :disabled="unpaidSelected.length == 0"
        />
        <update-transaction-comission
          @onUpdate="fetchTransactions()"
          :affiliate="affiliate"
          v-if="hasEdit('affiliates')"
          :selected="selected"
        />
        <update-transaction-payment-method
          @onUpdate="fetchTransactions()"
          v-if="hasEdit('affiliates')"
          :selected="paidSelected"
        />
      </div>
      <template v-slot:item.startWeek="{ item }"> {{ item.weekName }}</template>
      <template v-slot:item.paid_at="{ item }">
        {{ formatDate(item.paid_at) }}</template
      >
      <template v-slot:item.created_date="{ item }">
        {{ item.created_at }}</template
      >
      <template v-slot:item.amount="{ value }">
        {{ formatPrice(value || 0) }}</template
      >
      <template v-slot:item.commission="{ value }">
        {{ formatPrice(value) }}</template
      >
      <template v-slot:item.commission_rate="{ value }"> {{ value }}%</template>
      <template v-slot:item.payout="{ value }">{{
        value == true ? "Paid" : "Unpaid"
      }}</template>
      <template v-slot:item.actions="{ value }">
        <div class="d-flex">
          <primary-button small height="30px">View</primary-button>
        </div>
      </template>
    </static-table-view>
  </div>
</template>

<script>
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import DateField from "@/components/ui/form/DateField.vue";
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { colCommissions, isLocal } from "@/assets/utils/firebase.utils";
import { fetchTransactionClients, mapDocs } from "@/assets/utils/doc.utils";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SelectField from "../ui/form/SelectField.vue";
import { payoutStatus } from "@/assets/constants/client.constants";
import moment from "moment-timezone";
import StaticTableView from "../ui/StaticTableView.vue";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import UpdateTransactionPaymentMethod from "./UpdateTransactionPaymentMethod.vue";
import UpdateTransactionComission from "./UpdateTransactionComission.vue";
import AffiliateWeeklyPayout from "./AffiliateWeeklyPayout.vue";
const defaultMonth = isLocal ? 12 : 3;

export default {
  props: {
    affiliate: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DateField,
    PrimaryButton,
    CloseButton,
    SelectField,
    StaticTableView,
    ClientAutocomplete,
    UpdateTransactionPaymentMethod,
    UpdateTransactionComission,
    AffiliateWeeklyPayout,
  },
  data() {
    return {
      dialog: false,
      payoutStatus,
      isLoading: true,
      selected: [],
      loading: false,
      list: [],
      form: {
        from: moment().subtract(defaultMonth, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        payout: "",
      },
    };
  },
  computed: {
    unpaidSelected() {
      return this.selected.filter((i) => i.payout == false);
    },
    paidSelected() {
      return this.selected.filter((i) => i.payout == true);
    },
    total() {
      let affiliate =
        this.affiliates.find((i) => i.id == this.affiliate.id) ||
        this.affiliate;
      return parseFloat(affiliate.transaction_amount);
    },
    earned() {
      let affiliate =
        this.affiliates.find((i) => i.id == this.affiliate.id) ||
        this.affiliate;
      return (
        parseFloat(affiliate.commission) + parseFloat(affiliate.commission_owed)
      );
    },
    paid() {
      let affiliate =
        this.affiliates.find((i) => i.id == this.affiliate.id) ||
        this.affiliate;
      return parseFloat(affiliate.commission);
    },
    owed() {
      let affiliate =
        this.affiliates.find((i) => i.id == this.affiliate.id) ||
        this.affiliate;
      return parseFloat(affiliate.commission_owed);
    },
    affiliateId() {
      return this.$route.params.id;
    },
    headers() {
      let doc = {
        created_at: "Date",
        client_name: "Client Name",
        reference_no: "Reference Number",
        amount: "Amount",
        client_type: "Client Type",
        commission: "Commission Amount",
        commission_rate: "Commission %",
        payout: "Payout Status",
        paid_at: "Payout Date",
        payout_payment_method: "Payment Method",
      };
      return getHeaders(doc);
    },
  },
  methods: {
    resetFilter() {
      this.form = {
        from: moment(this.affiliate.created_at)
          .startOf("day")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        payout: "",
      };
      this.fetchTransactions(this.options);
    },
    query() {
      let queryList = [];
      let { payout, client } = this.form;
      let { start, end } = getStartEndDate(this.form);
      queryList.push(orderBy("created_at", "desc"));
      if (start && end) {
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      queryList.push(where("affiliate_id", "==", this.affiliateId));
      if (payout) {
        queryList.push(where("payout", "==", payout == "Paid"));
      }
      if (client) {
        queryList.push(where("customer_id", "==", client));
      }
      return query(colCommissions, ...queryList);
    },
    async fetchTransactions(options) {
      const vm = this;
      try {
        vm.loading = true;
        vm.options = options;
        vm.selected = [];
        vm.$refs.table.selected = [];
        vm.list = [];
        let docs = (await getDocs(vm.query())).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        let list = mapDocs(docs);
        vm.list = (await fetchTransactionClients(list)).map((i) => {
          return { ...i };
        });
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchTransactions();
  },
  beforeMount() {
    this.form.from = moment(this.affiliate.created_at)
      .startOf("day")
      .format("YYYY-MM-DD");
  },
};
</script>

<style></style>
