<template>
  <div>
    <div class="subtitle-1 grey--text pb-2">Search My Client</div>
    <form-wrapper>
      <v-row>
        <v-col cols="4" class="d-flex align-end">
          <input-field
            hide-details
            class="flex-1"
            clearable
            @input="onSearch"
            @enter="onSearch"
            v-model="search"
            :label="isAdvance ? 'Search by name or email or phone' : ''"
            placeholder="Search by name or email or phone"
          />
        </v-col>
        <template v-if="isAdvance">
          <v-col cols="2">
            <select-field
              v-model="form.customer_type"
              :items="clientStatusTypes"
              hide-details
              clearable
              placeholder="All"
              label="Status"
            />
          </v-col>
          <v-col cols="2">
            <date-field hide-details v-model="form.from" label="Added From" />
          </v-col>
          <v-col cols="2">
            <date-field hide-details v-model="form.to" label="Added To" />
          </v-col>
          <v-col cols="4">
            <autocomplete
              v-model="form.affiliate_id"
              placeholder="All"
              clearable
              :items="affiliates"
              hide-details
              label="Affiliate"
            />
          </v-col>
          <v-col cols="4">
            <phone-field
              hide-details
              clearable
              v-model="form.phone"
              label="Phone Number"
            />
          </v-col>

          <v-col cols="4" class="d-flex align-end">
            <primary-button @click="applyFilter()">Search</primary-button>
            <text-button @click="updateIsAdvance(false)"
              >Basic Search</text-button
            >
            <text-button @click="resetFilter()"> Reset Filter</text-button>
          </v-col>
        </template>
        <v-col
          :cols="isAdvance ? 12 : 8"
          class="d-flex justify-end align-center"
          :class="isAdvance ? 'mt-5' : ''"
        >
          <template>
            Quick Filters
            <select-field
              class="ml-2"
              placeholder="All"
              v-model="quickForm.customer_type"
              :items="clientStatusTypes"
              hide-details
              @input="onQuickFormChange"
              clearable
              style="max-width: 150px"
            />
            <select-field
              hide-details
              placeholder="All"
              @input="onQuickFormChange"
              clearable
              v-model="quickForm.client_type"
              :items="filterredTypes"
              style="max-width: 120px"
              class="mx-4"
            />
          </template>
          <client-table-print :headers="headers" :items="items" />
          <select-field
            class="ml-4"
            v-model="importExport"
            clearable
            :loading="loading"
            @input="onImportExport"
            placeholder="Import / Export"
            :items="['Import CSV', 'Export CSV']"
            hide-details
            style="max-width: 180px"
          />
          <add-button class="ml-4" to="add-client" v-if="hasEdit('customers')"
            >Add Lead/Client</add-button
          >
        </v-col>
      </v-row>
      <v-btn
        text
        v-if="!isAdvance"
        @click="updateIsAdvance(true)"
        class="primary--text px-0 mt-2"
        >Advanced Search</v-btn
      >
    </form-wrapper>
    <client-import-dialog ref="clientImport" @onUpdate="$emit('onUpdate')" />
  </div>
</template>

<script>
import AddButton from "../ui/buttons/AddButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import TextButton from "../ui/buttons/TextButton.vue";
import Autocomplete from "../ui/form/Autocomplete.vue";
import DateField from "../ui/form/DateField.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import PhoneField from "../ui/form/PhoneField.vue";
import SelectField from "../ui/form/SelectField.vue";
import ClientTablePrint from "./ClientTablePrint.vue";
import ClientImportDialog from "./ClientImportDialog.vue";
import { mapActions } from "vuex";
import { exportCSVFile } from "@/assets/utils/csv.utils";
import { fetchClientByIds, formatDate } from "@/assets/utils/common.utils";
import { transformCustomer } from "@/assets/utils/doc.utils";
import { algoliaClient } from "@/assets/utils/algolia.utils";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      importExport: "",
    };
  },
  computed: {
    search: {
      get() {
        return this.$store.state.clientFilter.search;
      },
      set(value) {
        this.updateSearch(value);
      },
    },
    form: {
      get() {
        return this.$store.state.clientFilter.form;
      },
      set(value) {
        this.updateForm(value);
      },
    },
    quickForm: {
      get() {
        return this.$store.state.clientFilter.quickForm;
      },
      set(value) {
        this.updateQuickForm(value);
      },
    },
    isAdvance: {
      get() {
        return this.$store.state.clientFilter.isAdvance;
      },
      set(value) {
        this.updateIsAdvance(value);
      },
    },
    filterredTypes() {
      let per = {
        MR: "mr",
        PPD: "ppd",
        Both: "mr_ppd",
      };
      return this.clientTypes.filter((i) =>
        this.hasPermission("customers", per[i.value])
      );
    },
  },
  methods: {
    ...mapActions("clientFilter", [
      "updateSearch",
      "updateQuickForm",
      "updateForm",
      "updateIsAdvance",
      "resetFilters",
    ]),
    onSearch() {
      if (this.isAdvance) return;
      if (!this.search) {
        this.onQuickFormChange();
        return;
      }
      if (this.search.length < 3) return;
      this.onQuickFormChange();
    },
    applyFilter() {
      const updatedForm = { ...this.form, search: this.search };
      this.updateForm(updatedForm);
      this.$emit("onFilterApply", updatedForm);
    },
    onQuickFormChange() {
      this.updateQuickForm(this.quickForm);
      this.$emit("onFilterApply", { ...this.quickForm, search: this.search });
    },
    resetFilter() {
      this.resetFilters();
      this.$emit("onFilterApply", {});
    },
    async onImportExport() {
      const vm = this;
      if (vm.importExport == "Export CSV") {
        try {
          vm.importExport = "";
          vm.loading = true;
          let { form, indexName, filters } = vm.$parent.$parent;
          let searchText = form.search?.trim().toLowerCase() || "*";
          const index = algoliaClient.initIndex(indexName);
          let queryOptions = {
            page: 0,
            hitsPerPage: 10000,
            cacheable: true,
            // queryType: "prefixNone",
            exactOnSingleWordQuery: "attribute",
            typoTolerance: false,
          };
          let { facetFilters, filterQuery } = filters;
          if (filterQuery) queryOptions.filters = filterQuery;
          if (facetFilters) queryOptions.facetFilters = facetFilters;
          let res = await index.search(searchText, queryOptions);
          this.total = res.nbHits;
          let ids = res.hits.map((i) => i.objectID);
          let items = await fetchClientByIds(ids);

          items = items
            .map((i) => transformCustomer(i))
            .map((i) => {
              let x = Math.floor(Math.random() * 10 + 1);
              i.client_type = this.getClientType(i);
              return { ...i, contract_status: x % 2 == 0 };
            });

          let headers = [...vm.headers].filter((i) => i.value != "actions");
          items = items.map((i) => {
            let doc = {};
            headers.forEach((h) => {
              doc[h.value] = i[h.value] == undefined ? "" : i[h.value];
              if (h.value == "affiliate_id") {
                doc[h.value] = vm.getAffiliateName(i[h.value]) || "";
              }
              if (h.value == "status") {
                let status = vm.clientStatusTypes.find(
                  (s) => s.value == i.customer_type
                );
                doc[h.value] = status ? status.name : "";
              }
            });
            return doc;
          });
          let headerDoc = {};
          headers.forEach((i) => (headerDoc[i.value] = i.text));
          exportCSVFile(headerDoc, items, `Clients ${formatDate(new Date())}`);
          vm.loading = false;
        } catch (error) {
          vm.handleError(error);
        }
      }
      if (vm.importExport == "Import CSV") {
        vm.$refs.clientImport.dialog = true;
        vm.importExport = "";
      }
    },
  },
  beforeMount() {
    this.applyFilter();
  },
  components: {
    FormWrapper,
    InputField,
    SelectField,
    DateField,
    PrimaryButton,
    AddButton,
    TextButton,
    Autocomplete,
    PhoneField,
    ClientTablePrint,
    ClientImportDialog,
  },
};
</script>

<style></style>
