<template>
  <layout title="Transaction History">
    <transaction-filter @onFilterApply="onFilterApply" ref="filter" />
    <dynamic-table-view
      ref="table"
      @onPageChange="fetchTransactions"
      :show-select="showSelect"
      :server-items-length="total"
      :headers="headers"
      v-model="selected"
      :loading="loading"
      :list="list"
    >
      <div slot="left" class="d-flex mt-2">
        <error-button
          :disabled="selected.length == 0"
          @click="removeSelectedTransaction()"
          >Delete Selected</error-button
        >
      </div>
      <template v-slot:item.amount="{ value }">
        {{ formatPrice(value) }}
      </template>
      <template v-slot:item.name="{ item, value }">
        <router-link
          class="text-decoration-none"
          :to="{ name: 'client-profile', params: { id: item.customer_id } }"
        >
          {{ value }}
        </router-link>
      </template>
      <template v-slot:item.affiliate_id="{ value, item }">
        <div class="d-flex">
          {{ getAffiliateName(value) }}
          <client-other-affiliates v-if="value" :item="item" />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <edit-transaction
            @onUpdate="fetchTransactions(options)"
            :item="item"
            v-if="hasEdit('transaction-log')"
          />
          <delete-icon-button
            @click="removeTransaction(item) && item.payout == false"
            v-if="isRemovable(item)"
          />
        </div>
      </template>
    </dynamic-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import DynamicTableView from "@/components/ui/DynamicTableView.vue";
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import { colTransactions, db } from "@/assets/utils/firebase.utils";
import {
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import ClientOtherAffiliates from "@/components/clients/ClientOtherAffiliates.vue";
import TransactionFilter from "@/components/transactions/TransactionFilter.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import ErrorButton from "@/components/ui/buttons/ErrorButton.vue";
import EditTransaction from "@/components/transactions/EditTransaction.vue";
export default {
  components: {
    Layout,
    DynamicTableView,
    ClientOtherAffiliates,
    TransactionFilter,
    DeleteIconButton,
    ErrorButton,
    EditTransaction,
  },
  data() {
    return {
      selected: [],
      options: {},
      form: {},
      total: 20,
      list: [],
      loading: false,
    };
  },
  computed: {
    showSelect() {
      let { hasPermission } = this;
      return hasPermission("transaction-log", "delete_mpm");
      // return (
      // hasPermission("transaction-log", "delete_mpm") ||
      // hasPermission("transaction-log", "delete_credit_card")
      // );
    },
    headers() {
      let doc = {
        created_at: "Date",
        reference_no: "Reference Number",
        amount: "Amount",
        type: "Type",
        payment_method: "Payment Method",
        name: "Client Name",
        email: "Client Email",
        client_type: "Client Type",
        affiliate_id: "Affiliate Name",
        actions: "Actions",
      };
      let sortable = [
        // "name",
        // "amount",
        // "payment_method",
        "created_at",
        // "client_type",
      ];

      return getHeaders(doc).map((i) => {
        i.sortable = sortable.indexOf(i.value) != -1;
        return i;
      });
    },
  },
  methods: {
    removeTransaction({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this transaction?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colTransactions, id));
              vm.notifySuccess("Transaction has been deleted successfully.");
              vm.fetchTransactions(vm.options);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    removeSelectedTransaction() {
      const vm = this;
      vm.$confirm.show({
        message:
          "Are you sure you want to delete all the selected transactions?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              const batch = writeBatch(db);
              vm.selected.forEach((i) =>
                batch.delete(doc(colTransactions, i.id))
              );
              await batch.commit();
              vm.notifySuccess("Transaction has been deleted successfully.");
              vm.fetchTransactions(vm.options);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    isRemovable({ payment_type }) {
      if (payment_type == "MPM")
        return this.hasPermission("transaction-log", "delete_mpm");
      return false;
    },
    onFilterApply(event) {
      this.form = event;
      let options = this.$refs.table.options;
      this.$refs.table.options = { ...options, page: 1 };
    },
    query(options) {
      let sortBy = options.sortBy[0];
      let sortDesc = options.sortDesc[0];
      let { client_type, payment_method, affiliate_id, search } = this.form;
      let queryList = [];
      let { start, end } = getStartEndDate(this.form);
      if (start && end) {
        queryList.push(orderBy("created_at", sortDesc ? "desc" : "asc"));
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      let filterredTypes = this.$refs.filter.filterredTypes;
      if (client_type) {
        queryList.push(where("client_type", "==", client_type));
      } else if (filterredTypes.length != 3) {
        let types = filterredTypes.map((i) => i.value);
        queryList.push(where("client_type", "in", types));
      }
      if (payment_method)
        queryList.push(where("payment_method", "==", payment_method));
      if (affiliate_id) {
        queryList.push(where("affiliates", "array-contains", affiliate_id));
      } else if (search) {
        queryList.push(
          where("terms", "array-contains", search.trim().toLowerCase())
        );
      }
      if (sortBy) {
        if (sortBy == "created_at") {
          if (!start) {
            queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
          }
        } else {
          queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
        }
      } else if (!start) {
        queryList.push(orderBy("created_at", "desc"));
      }

      return {
        countQuery: query(colTransactions, ...queryList),
        pageQuery: query(
          colTransactions,
          ...[...queryList, ...this.getPaginationQuery(options)]
        ),
      };
    },
    async fetchTransactions(options) {
      const vm = this;
      if (vm.loading == true) return;
      try {
        vm.loading = true;
        vm.options = options;
        vm.selected = [];
        vm.list = [];
        this.$refs.table.selected = [];
        let page = options.page;
        let { countQuery, pageQuery } = vm.query(options);
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        let list = mapDocs(docs).map((i) => {
          return {
            ...i,
            type: i.type?.startsWith("CC:Sale") ? "Sale" : "Refund",
            isSelectable: this.isRemovable(i),
            affiliate_id: i.affiliates[0] || "",
          };
        });
        vm.list = list;
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.form = this.filters.transactions || {};
  },
};
</script>
<style></style>
