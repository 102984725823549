<template>
  <delete-icon-button
    @click="deleteContract(item)"
    v-if="hasDelete('client-agreements')"
  />
</template>

<script>
import { deleteDoc, doc } from "firebase/firestore";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import { colClientAgreements } from "@/assets/utils/firebase.utils";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DeleteIconButton },
  methods: {
    async deleteContract(item) {
      this.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          await deleteDoc(doc(colClientAgreements, item.id));
          this.$emit("onDelete");
        },
      });
    },
  },
};
</script>

<style></style>
