<template>
  <div>
    <please-wait v-if="isLoading"></please-wait>
    <div class="text-center mt-12 pt-12" v-if="isSuccess">
      <v-icon size="75px" color="success">mdi-check-circle-outline</v-icon>
      <div class="text-center text-h6 mt-3">
        Thank You! Your agreement has been successfully signed.
      </div>
    </div>
    <div v-else-if="agreement != null">
      <v-card>
        <v-card-title> Sign Agreement </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px; overflow-y: auto">
          <div v-html="agreement"></div>
        </v-card-text>
        <v-card-title> Digital Signature </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <form-wrapper @submit="submit()">
            <div style="max-width: 500px">
              <input-field
                v-model="name"
                label="Enter your name"
                rules="required"
              />
              <label class="body-small">Enter your signature</label>
              <v-card
                width="500px"
                height="250px"
                style="border-color: grey"
                outlined
              >
                <VueSignaturePad
                  width="500px"
                  height="200px"
                  ref="signaturePad"
                />
                <v-divider class="mx-5" />
                <div class="py-3 d-flex primary--text justify-center">
                  <span v-if="name" class="mr-1"> {{ name }}, </span>
                  {{ date }}
                </div>
              </v-card>
              <v-btn outlined class="my-3" @click="undo()"
                >Reset Signature</v-btn
              >
              <div class="text-subtitle-2 black--text">
                By entering name, my signature and clicking submit below. I
                confirm that I have read, understand and have agreed to all
                terms.
              </div>
              <div class="d-flex align-center mt-3">
                <primary-button :loading="loading" type="submit"
                  >Submit Now</primary-button
                >
                <v-btn
                  :disabled="loading"
                  text
                  class="ml-3"
                  color="primary"
                  @click="print()"
                  height="40px"
                >
                  <v-icon left>mdi-printer</v-icon>
                  Print Preview</v-btn
                >
              </div>
            </div>
          </form-wrapper>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { functions } from "@/assets/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { httpsCallable } from "firebase/functions";
import InputField from "@/components/ui/form/InputField.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import moment from "moment-timezone";
export default {
  components: {
    PleaseWait,
    InputField,
    PrimaryButton,
    FormWrapper,
  },
  data() {
    return {
      agreement: null,
      name: "",
      loading: false,
      isSuccess: false,
      date: moment().format("MMM DD, YYYY"),
      isLoading: true,
    };
  },
  methods: {
    print() {
      const printWindow = window.open(
        "",
        "",
        `width=${window.innerWidth},height=${window.innerHeight}`
      );

      // Write the HTML string into the new window
      printWindow.document.write(
        `
        <style>
    /* Styles for screen */
    body {
      padding: 20px;
    }

    /* Styles specifically for print */
    @media print {
      body {
        background-color: #f0f0f0 !important; /* Ensure the background color is printed */
        -webkit-print-color-adjust: exact; /* For Safari/Chrome */
        print-color-adjust: exact; /* For Firefox and other browsers */
      }
    }
  </style>
       ${this.agreement}`
      );

      // Close the document to trigger the print dialog
      printWindow.document.close();

      // Wait for the content to load and then print
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close(); // Close the window after printing
      };
    },
    async fetchAgreement() {
      try {
        this.isLoading = true;
        let sendContract = httpsCallable(functions, "manageAgreement");
        let res = await sendContract({
          id: this.$route.params.id,
          type: "get-agreement",
        });
        this.agreement = res.data;
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async submit() {
      try {
        this.loading = true;
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (isEmpty) {
          this.notifyError("Please enter your signature");
          this.loading = false;
          return;
        }
        let signContract = httpsCallable(functions, "manageAgreement");
        await signContract({
          id: this.$route.params.id,
          type: "sign-agreement",
          name: this.name,
          signature: data.split("data:image/png;base64,")[1],
        });
        this.isSuccess = true;

        this.loading = true;
      } catch (error) {
        this.handleError(error);
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
  },
  mounted() {
    this.fetchAgreement();
  },
};
</script>

<style></style>
