import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";

import Message from "vue-m-message";
import "vue-m-message/dist/index.css";
import ConfirmModal from "./components/ui/confirm/index.js";
import LoaderModal from "./components/ui/loader/index.js";
import commonMixin from "./mixins/common.mixin";
import { VueMaskDirective } from "v-mask";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueApexCharts from "vue-apexcharts";
import VCalendar from "v-calendar";
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyCquNeDdlxVYTQJ6DKtZuzw4lVBPQJ4DEI",
  installComponents: true,
  vueGoogleMapsCompatibility: false,
});
Vue.use(VCalendar, {
  componentPrefix: "vc",
});
Vue.directive("mask", VueMaskDirective);
Vue.use(Message);
Vue.use(ConfirmModal);
Vue.use(LoaderModal);
Vue.mixin(commonMixin);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
