<template>
  <div class="py-3">
    <div class="d-flex">
      <div class="title flex-grow-1">Tasks</div>
      <primary-outlined-button
        color="success"
        @click="undo(false)"
        class="mr-3"
        height="54px"
        :disabled="loading || completedUndoList.length == 0"
      >
        Undo
      </primary-outlined-button>

      <primary-button
        color="success"
        class="mr-3"
        height="54px"
        @click="markCompleted(true)"
        :disabled="loading || selectedTasks.length == 0"
      >
        Mark As Completed
      </primary-button>

      <add-schedule-event
        type="task"
        v-if="hasEdit('customers')"
        @onEventUpsert="fetchEvents()"
      />
    </div>
    <v-card outlined tile height="400px" class="overflow-y-auto mt-3 pa-3">
      <v-progress-linear indeterminate v-if="loading" />
      <v-list>
        <div
          v-for="eventType in ['Overdue', 'Today', 'Upcoming']"
          :key="eventType"
        >
          <div v-if="getEvents(eventType).length" class="mb-3">
            <v-chip dark :color="getClipColor(eventType)" class="mb-3">{{
              eventType
            }}</v-chip>
            <v-divider />
            <div v-for="item in getEvents(eventType)" :key="item.id">
              <v-list-item>
                <v-list-item-action v-if="hasEdit('customers')">
                  <v-checkbox
                    hide-details
                    color="primary"
                    :value="item.id"
                    class="mt-0 pt-0"
                    :disabled="loading"
                    v-model="selectedTasks"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.subject }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <div class="mr-1">
                      {{ item.due_date }}
                    </div>
                    <team-task-history :task="item" />
                    <add-schedule-event
                      type="task"
                      @onEventUpsert="fetchEvents()"
                      :isEdit="true"
                      :showButton="true"
                      :item="item"
                    />
                    <delete-icon-button
                      @click="remove(item)"
                      v-if="hasDelete('schedule')"
                    />
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
          </div>
        </div>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <div class="text-right">
          <v-btn
            v-on="on"
            text
            color="primary"
            class="text-none text-decoration-underline mt-3"
          >
            View Completed Team Tasks
          </v-btn>
        </div>
      </template>
      <dialog-card title="Completed Team Tasks" @close="dialog = false">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <primary-outlined-button
            color="success"
            @click="undo(true)"
            class="mr-3"
            height="54px"
            :disabled="loading || incompletedUndoList.length == 0"
          >
            Undo
          </primary-outlined-button>

          <primary-button
            color="success"
            class="mr-3"
            height="54px"
            @click="markCompleted(false)"
            :disabled="loading || selectedCompletedTasks.length == 0"
          >
            Mark As Completed
          </primary-button>
        </div>
        <v-progress-linear v-if="loading" indeterminate />
        <v-list>
          <div v-for="item in completedTasks" :key="item.id">
            <v-list-item>
              <v-list-item-action v-if="hasEdit('customers')">
                <v-checkbox
                  hide-details
                  color="primary"
                  :disabled="loading"
                  v-model="selectedCompletedTasks"
                  class="mt-0 pt-0"
                  :value="item.id"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-decoration-line-through">
                  {{ item.subject }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <div class="mr-1">
                    {{ item.due_date }}
                  </div>
                  <team-task-history :task="item" />
                  <add-schedule-event
                    type="task"
                    :isEdit="true"
                    :showButton="true"
                    :item="item"
                    @onEventUpsert="fetchEvents()"
                  />
                  <delete-icon-button
                    @click="removeTask(item)"
                    v-if="hasDelete('schedule')"
                  />
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
        <div slot="footer"></div>
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import AddScheduleEvent from "@/components/schedule/AddScheduleEvent.vue";
import {
  doc,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { colTeamSchedules, db } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
import { getDate } from "@/assets/utils/common.utils";
import DialogCard from "@/components/ui/DialogCard.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import TeamTaskHistory from "./TeamTaskHistory.vue";
import scheduleMixin from "@/mixins/schedule.mixin";
import _ from "lodash";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import PrimaryOutlinedButton from "@/components/ui/buttons/PrimaryOutlinedButton.vue";
export default {
  data() {
    return {
      loading: true,
      events: [],
      dialog: false,
      selectedTaskId: null,
      selectedTasks: [],
      selectedCompletedTasks: [],
      completedUndoList: [],
      incompletedUndoList: [],
    };
  },
  components: {
    AddScheduleEvent,
    DialogCard,
    DeleteIconButton,
    TeamTaskHistory,
    PrimaryButton,
    PrimaryOutlinedButton,
  },
  computed: {
    completedTasks() {
      let list = this.events.filter((i) => i.is_completed == true);
      return [...list].reverse();
    },
  },
  mixins: [scheduleMixin],
  methods: {
    getClipColor(type) {
      switch (type) {
        case "Overdue":
          return "error";
        case "Upcoming":
          return "warning";
        default:
          return "success";
      }
    },
    getEvents(type) {
      let events = this.events.filter((i) => i.is_completed == false);
      switch (type) {
        case "Overdue":
          return events.filter((i) =>
            moment(i.from_date).isBefore(moment(), "date")
          );
        case "Today":
          return events.filter((i) =>
            moment(i.from_date).isSame(moment(), "date")
          );
        case "Upcoming":
          return events.filter((i) =>
            moment(i.from_date).isAfter(moment(), "date")
          );
        default:
          return [];
      }
    },
    remove(item) {
      this.removeTask(item).then(() => {
        this.fetchEvents();
      });
    },
    async fetchEvents() {
      try {
        this.loading = true;
        let eventQuery = query(
          colTeamSchedules,
          orderBy("from_date", "asc"),
          where("client", "==", this.$route.params.id)
        );
        this.events = mapDocs((await getDocs(eventQuery)).docs).map((i) => {
          i.due_date = moment(`${i.from_date}T${i.from_time}`).format(
            "MM/DD/YYYY hh:mm A"
          );
          return i;
        });
        this.loading = false;
        this.selectedTaskId = "";
      } catch (error) {
        this.handleError(error);
      }
    },

    async markCompleted(is_completed) {
      let message = `Are you sure, Do you want to mark as completed for selected tasks?`;
      if (!is_completed) {
        message =
          "Are you sure you want to mark the selected tasks as incompleted?";
      }
      this.$confirm.show({
        message,
        onConfirm: async (vm) => {
          try {
            vm.loading = true;
            for (let items of _.chunk(
              is_completed ? this.selectedTasks : this.selectedCompletedTasks,
              250
            )) {
              let batch = writeBatch(db);
              items.forEach((i) => {
                batch.update(doc(colTeamSchedules, i), {
                  is_completed,
                });
                batch.set(
                  doc(
                    colTeamSchedules,
                    i,
                    "history",
                    new Date().getTime().toString()
                  ),
                  {
                    created_at: getDate(),
                    uid: this.uid,
                    name: `${this.user.first_name} ${this.user.last_name}`,
                    action: is_completed
                      ? "Marked Completed"
                      : "Marked Incompleted",
                  }
                );
              });
              await batch.commit();
            }
            if (is_completed) {
              this.completedUndoList = this.selectedTasks;
              this.selectedTasks = [];
            } else {
              this.incompletedUndoList = this.selectedCompletedTasks;
              this.selectedCompletedTasks = [];
            }
            this.$store.dispatch("fetchUserTaskCount");
            this.fetchEvents();
            vm.loading = false;
            vm.dialog = false;
          } catch (error) {
            vm.loading = false;
            this.handleError(error);
          }
        },
      });
    },
    async undo(is_completed) {
      this.$confirm.show({
        message: "Are you sure you want to undo the changes?",
        onConfirm: async (vm) => {
          try {
            vm.loading = true;
            for (let items of _.chunk(
              is_completed ? this.incompletedUndoList : this.completedUndoList,
              500
            )) {
              let batch = writeBatch(db);
              items.forEach((i) => {
                batch.update(doc(colTeamSchedules, i), {
                  is_completed,
                });
                batch.set(
                  doc(
                    colTeamSchedules,
                    i,
                    "history",
                    new Date().getTime().toString()
                  ),
                  {
                    created_at: getDate(),
                    uid: this.uid,
                    name: `${this.user.first_name} ${this.user.last_name}`,
                    action: is_completed
                      ? "Marked Completed"
                      : "Marked Incompleted",
                  }
                );
              });
              await batch.commit();
            }
            if (!is_completed) {
              this.completedUndoList = [];
            } else {
              this.incompletedUndoList = [];
            }

            this.fetchEvents();
            this.$store.dispatch("fetchUserTaskCount");
            vm.loading = false;
            vm.dialog = false;
          } catch (error) {
            vm.loading = false;
            this.handleError(error);
          }
        },
      });
    },
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>

<style></style>
