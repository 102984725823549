<template>
  <v-app-bar app class="app-header px-0" :height="height" flat>
    <div style="width: 100%">
      <template v-if="isAgreement">
        <div class="d-flex flex-1 justify-center">
          <div v-if="isStaging" class="white--text text-h3">Staging</div>
          <v-img
            v-else
            src="../../assets/images/logo.png"
            max-width="160px"
            contain
            alt=""
          />
        </div>
      </template>
      <template v-else>
        <v-container class="pb-0">
          <div class="d-flex flex-1 align-center my-5">
            <div class="flex-1" v-if="isLogin">
              <v-btn icon dark large>
                <v-badge
                  v-if="userTaskCount"
                  :content="userTaskCount"
                  color="red"
                  overlap
                  :value="userTaskCount"
                >
                  <v-icon>mdi-clipboard-check</v-icon>
                </v-badge>
                <v-icon v-else>mdi-clipboard-check</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-1 justify-center">
              <div v-if="isStaging" class="white--text text-h3">Staging</div>
              <v-img
                v-else
                src="../../assets/images/logo.png"
                max-width="160px"
                contain
                alt=""
              />
            </div>
            <div class="d-flex flex-1 justify-end align-center" v-if="isLogin">
              <h4 class="white--text mb-1" style="height: 24px">
                {{ userName }}
              </h4>
              <v-menu v-model="menu" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" small dark>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0" dense single-line>
                  <v-list-item @click="logout()">
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <app-nav v-if="isLogin"></app-nav>
        </v-container>
        <template v-if="isCustomerPage">
          <div style="background: white; width: 100%; height: 45px">
            <div class="container pt-0">
              <v-tabs
                class="container tab-outlined pb-0"
                hide-slider
                v-model="tab"
              >
                <v-tab
                  class="text-none"
                  v-for="(tab, key) in tabs"
                  :disabled="isDisabled(tab)"
                  :key="key"
                  @click="openTab(key)"
                >
                  {{ tab }}
                </v-tab>
              </v-tabs>
              <v-divider></v-divider>
            </div>
          </div>
        </template>
      </template>
    </div>
  </v-app-bar>
</template>
<script>
import { signOut } from "firebase/auth";
import { auth, isStaging } from "@/assets/utils/firebase.utils";
import { mapGetters } from "vuex";
import AppNav from "./AppNav.vue";
import { eventBus } from "@/assets/utils/common.utils";
export default {
  components: { AppNav },
  data() {
    return {
      menu: false,
      isStaging: isStaging,
      tab: 0,
      taskCount: 0,
      tabs: {
        "client-profile": "Dashboard",
        "import-audit": "Import Audit",
        "credit-report": "Credit Report",
        "dispute-info": "Dispute Info",
        "client-dispute-letters": "Dispute Letters",
        "generate-letters": "Generate Letters ( MR)",
        "send-letters": "Send Letters (PPD)",
        "referral-payout": "Referral Payout",
        "contract-history": "Contract History",
        "client-transaction-history": "Transaction History",
      },
    };
  },
  computed: {
    isAgreement() {
      return this.$route.name == "agreement";
    },
    height() {
      if (this.isAgreement) return "100px";
      return this.isCustomerPage ? "177px" : "132px";
    },
    ...mapGetters(["isLogin", "userName", "userTaskCount"]),
    isCustomerPage() {
      let clientProfile = this.$route.matched.find(
        (i) => i.name == "client-dashboard"
      );
      return clientProfile != null;
    },
  },
  watch: {
    $route() {
      this.initTab();
    },
  },
  methods: {
    initTab() {
      let name = this.$route.name || this.$route?.matched[0]?.name;
      this.tab = Object.keys(this.tabs).indexOf(name);
    },
    isDisabled(item) {
      if (item == "Send Letters (PPD)" && !this.hasEdit("send-faxes"))
        return true;
      if (item == "Dispute Letters" && !this.hasRead("send-faxes")) return true;
      if (item == "Transaction History" && !this.hasRead("transaction-log"))
        return true;
      return false;
    },
    logout() {
      signOut(auth);
    },
    openTab(key) {
      let isSameRoute = this.$route.name == key;
      if (key == "client-profile" && isSameRoute) {
        eventBus.$emit("refreshProfile");
      }
      if (isSameRoute) return;
      this.$router.push({ name: key, params: { id: this.$route.params.id } });
    },
  },
  beforeMount() {
    this.initTab();
  },
};
</script>

<style lang="scss">
.app-header {
  background: #222a39 !important;
  .v-toolbar__content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
