<template>
  <layout title="Client Agreements">
    <dynamic-table-view
      :list="list"
      :loading="loading"
      ref="table"
      :headers="headers"
      @onPageChange="fetchAgreements"
      :server-items-length="total"
    >
      <div slot="left" class="d-flex">
        <search-field
          v-model="search"
          @clear="
            search = '';
            applySearchFilter();
          "
          @onEnter="applySearchFilter()"
        />
        <primary-button class="ml-3" @click="applySearchFilter()"
          >Search</primary-button
        >
      </div>
      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center">
          <add-contract @update="fetchContracts()" :item="item" />
          <client-agreement-preview-dialog :isIcon="true" :agreement="item" />
          <delete-agreement :item="item" @onDelete="applySearchFilter()" />
        </div>
      </template>
    </dynamic-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import { colClientAgreements } from "@/assets/utils/firebase.utils";
import {
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { mapDocs } from "@/assets/utils/doc.utils";
import DynamicTableView from "@/components/ui/DynamicTableView.vue";
import SearchField from "@/components/ui/form/SearchField.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import ClientAgreementPreviewDialog from "@/components/clients/ClientAgreementPreviewDialog.vue";
import DeleteAgreement from "@/components/mycompany/DeleteAgreement.vue";
import AddContract from "@/components/clients/clientProfile/AddContract.vue";
export default {
  components: {
    Layout,
    DynamicTableView,
    SearchField,
    PrimaryButton,
    ClientAgreementPreviewDialog,
    DeleteAgreement,
    AddContract,
  },
  data() {
    return {
      list: [],
      loading: false,
      total: 50,
      name: "",
      search: "",
      options: {},
    };
  },
  computed: {
    headers() {
      return [
        { text: "Agreement Name", value: "customer_name", sortable: true },
        { text: "Action", value: "action", width: "100px", sortable: false },
      ];
    },
  },
  methods: {
    applySearchFilter() {
      let options = this.$refs.table.options;
      this.$refs.table.options = { ...options, page: 1 };
    },
    query(options) {
      let queryList = [];
      let sortBy = options.sortBy[0];
      let sortDesc = options.sortDesc[0];
      if (sortBy) {
        queryList.push(orderBy("customer_name", sortDesc ? "desc" : "asc"));
      } else {
        queryList.push(orderBy("customer_name", "asc"));
      }
      if (this.search?.trim()) {
        queryList.push(
          where("_search", "array-contains", this.search.trim().toLowerCase())
        );
      }
      return {
        countQuery: query(colClientAgreements, ...queryList),
        pageQuery: query(
          colClientAgreements,
          ...[...queryList, ...this.getPaginationQuery(options)]
        ),
      };
    },
    async fetchAgreements(options) {
      const vm = this;
      try {
        if (vm.loading) return;
        vm.loading = true;
        vm.options = options;
        vm.list = [];
        let page = options.page;
        let { countQuery, pageQuery } = vm.query(options);
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        vm.list = mapDocs(docs);
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
};
</script>

<style>
.status-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}
</style>
